// Override default variables before the import
// $body-bg: #eaf4ec;

@import-normalize; /* bring in normalize.css styles */

@import-typeface-roboto;

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap';

// When switching from bootstrap 4->5 printing css was no longer included
// This will re add the css required for printing resume to pdf
@import '~bootstrap-print-css/css/bootstrap-print';

body {
  margin: 0;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// code {
//   font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
//     monospace;
// }

h3 {
  font-size: 1.5rem;
}

h4 {
  font-size: 1.2rem;
}

h5 {
  font-size: 1.2rem;
  font-weight: 300;
}

li {
  font-weight: 300;
}
